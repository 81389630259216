<template>
<g>
  <svg:style>
    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke:#aeaeae;
      stroke-width: 0.25px;
    }

    .cls-4,
    .cls-5,
    .cls-6,
    .cls-7,
    .cls-8,
    .cls-9 {
      stroke-linecap:round;
      stroke-linejoin:round;
    }
    .cls-4 {
      fill:url(#inox-gradient);
    }
    .cls-5 {
      fill:url(#inox-gradient-2);
    }
    .cls-6 {
      fill:url(#inox-gradient-3);
    }
    .cls-7 {
      fill:url(#glass-pattern);
    }
    .cls-8 {
      fill:url(#glass-pattern);
    }
    .cls-9 {
      fill:url(#glass-pattern);
    }
  </svg:style>

  <linearGradient id="handle-gradient"
                  :x1="doorLeftWidth1 + 11.33"
                  :y1="doorTopHeight1 + 150.96"
                  :x2="doorLeftWidth1 + 17.01"
                  :y2="doorTopHeight1 + 150.96"
                  gradientUnits="userSpaceOnUse">
    <stop offset="0.01" stop-color="#babbbc"/>
    <stop offset="0.29" stop-color="#989a9e"/>
    <stop offset="0.65" stop-color="#bdbdc1"/>
    <stop offset="0.87" stop-color="#9d9fa2"/>
    <stop offset="1" stop-color="#a6a8aa"/>
    <stop offset="1" stop-color="#c2c5c9"/>
  </linearGradient>

  <linearGradient id="inox-gradient"
                  :x1="doorLeftWidth1 + 1278.96"
                  :y1="doorTopHeight1 + 4095.44"
                  :x2="doorLeftWidth1 + 1331.41"
                  :y2="doorTopHeight1 + 4095.44"
                  xlink:href="#handle-gradient"/>

  <linearGradient id="inox-gradient-2"
                  :x1="doorLeftWidth1 + 1301.86"
                  :y1="doorTopHeight1 + 4022.92"
                  :x2="doorLeftWidth1 + 1334.35"
                  :y2="doorTopHeight1 + 4022.92"
                  xlink:href="#handle-gradient"/>

  <linearGradient id="inox-gradient-3"
                  :x1="doorLeftWidth1 + 1278.77"
                  :y1="doorTopHeight1 + 3950.28"
                  :x2="doorLeftWidth1 + 1331.41"
                  :y2="doorTopHeight1 + 3950.28"
                  xlink:href="#handle-gradient"/>

  <g id="r07">
    <path id="inox"
          v-if="showInox"
          class="cls-4"
          :d="`
          M ${doorLeftWidth1 + 1279} , ${doorTopHeight1 + 4128.61}
          a 233.67 , 233.67 , 0 , 0 , 0 , 21.92 -66.34
          h 30.53 
          a 265.61 , 265.61 , 0 , 0 , 1-19 , 66.34
          H 1279
          Z `"
          transform="translate(-1232.45 -3871.69)"/>

    <path id="inox-2"
          v-if="showInox"
          data-name="inox"
          class="cls-5"
          :d="`
          M ${doorLeftWidth1 + 1332.27} , ${doorTopHeight1 + 4056.05}
          a 265.78 , 265.78 , 0 , 0 , 0 , 0 -66.27
          l -30.42.08
          a 233.54 , 233.54 , 0 , 0 , 1 , 0 , 66.19
          h 30.42 
          Z `"
          transform="translate(-1232.45 -3871.69)"/>

    <path id="inox-3"
          v-if="showInox"
          data-name="inox"
          class="cls-6"
          :d="`
          M ${doorLeftWidth1 + 1278.77} , ${doorTopHeight1 + 3917}
          a 235.24 , 235.24 , 0 , 0 , 1 , 22.11 , 66.52
          h 30.53
          a 267.08 , 267.08 , 0 , 0 , 0 -19.06 -66.52
          h -33.58
          Z `"
          transform="translate(-1232.45 -3871.69)"/>

    <path id="glass"
          class="cls-7"
          :d="`
          M ${doorLeftWidth1 + 1327.27} , ${doorTopHeight1 + 3995.44}
          a 260.47 , 260.47 , 0 , 0 , 1 , 0 , 55
          l -18.94 -.06
          a 239 , 239 , 0 , 0 , 0 , 0 -54.86
          Z `"
          transform="translate(-1232.45 -3871.69)"/>

    <path id="glass-2"
          data-name="glass"
          class="cls-8"
          :d="`
          M ${doorLeftWidth1 + 1287.93} , ${doorTopHeight1 + 3922.77}
          a 241 , 241 , 0 , 0 , 1 , 17.71 , 55.21
          h 19.19
          a 261.36 , 261.36 , 0 , 0 , 0 -16.13 -55.21
          h -20.76
          Z `"
          transform="translate(-1232.45 -3871.69)"/>

    <path id="glass-3"
          data-name="glass"
          class="cls-9"
          :d="`
          M ${doorLeftWidth1 + 1308.69} , ${doorTopHeight1 + 4123}
          a 259.94 , 259.94 , 0 , 0 , 0 , 16.1 -55
          h -19.15
          a 239.43 , 239.43 , 0 , 0 , 1 -17.63 , 55
          h 20.68
          Z `"
          transform="translate(-1232.45 -3871.69)"/>
  </g>
</g>
</template>

<script>
export default {
  props: [
    'doorWidth',
    'doorHeight',
    'doorLeftWidth',
    'doorTopHeight',
    'doorGlassImage',
    'showInox',
  ],
  computed: {
    doorLeftWidth1() {
      return this.doorLeftWidth + this.doorWidth/2 - 71
    },
    doorTopHeight1() {
      return this.doorTopHeight + this.doorHeight/2 - 153
    },
  }
}
</script>
